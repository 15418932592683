import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "travel-card"
    }}>{`Travel Card`}</h1>
    <p>{`This service mainly serves as simplifying proxy towards NOD-Backoffice and PL4 with regards to travel cards,
converting generalized SOAP calls into a more user friendly REST service.
Many of the endpoints provided in travel-card returns a pickupid from NOD which must be used to retrieve ticket/card
commands.`}</p>
    <h2 {...{
      "id": "backoffice"
    }}>{`Backoffice`}</h2>
    <p>{`Backoffice is a VPN protected service providing a SOAP interface to NOD (Nasjonal Ordredatabase).
This service for provides interfaces creating and modifying travel cards and tickets. These commands
include but are not limited to buying/activating/deactivating tickets and activating/blocking travel cards.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The Travel Card API requires for every request a bearer token in the Authorization header either as a internal (Entur employee) or as a partner.
You may obtain the token using the Entur Auth0 bearer token provider, see `}<a parentName="p" {...{
        "href": "https://live-entur.devportal.apigee.io/content/authentication"
      }}>{`https://live-entur.devportal.apigee.io/content/authentication`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      